.component_rootCard {
  width: 300px;
}

.componentCard {
  z-index: 0;
  position: relative;
  aspect-ratio: 3 / 5;
  display: grid;
  color: var(--color-white);
  border-radius: var(--radius-15);
  transition: transform var(--duration-250) var(--ease);
  overflow: hidden;

  &:hover {
    transform: scale(1.04);
  }

  & > * {
    grid-area: 1 / -1;
  }

  & > .cardContent {
    z-index: 1;
  }

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}

.cardContent {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-32) var(--size-40) var(--size-20);

  & > .anchor {
    position: static;
  }
}

.anchor {
  position: relative;
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-30);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-heading-condensed);
  text-transform: uppercase;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.moreInformation {
  font-family: var(--font-family-base);
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-normal);
}
