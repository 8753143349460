.component {
  display: flex;
  align-items: center;
  gap: var(--size-8);
  padding: var(--size-12) 0;
  font-size: var(--font-size-14);
  color: var(--theme-error-color);

  & > .iconLayout {
    flex: 0 1 auto;
    width: var(--size-20);
  }
}
