.componentBase {
  --max-width-content: min(90vw, var(--container-max-width-xxl));

  z-index: 0;
  position: relative;
  display: grid;

  & > * {
    grid-area: 1 / -1;
  }

  & > .heroImageLayout {
    z-index: -1;
    height: 100lvh;
    top: 0;
  }

  & > .heroContentBorderLayout {
    width: 100%;
    height: 100svh;
  }

  & > .heroContentLayout {
    z-index: 1;
    margin-top: 100svh;
  }
}

.component {
  --width-title: 10ch;
  --font-size-title: var(--font-size-80-150);
}

.componentLanding {
  --width-title: 18ch;
  --font-size-title: var(--font-size-68-100);
}

.componentHeroImage {
  position: sticky;
  user-select: none;

  & > .imageContainer {
    width: 100%;
    height: 100%;
  }
}

.imageContainer {
  z-index: 0;
  position: relative;

  &::after {
    content: '';
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 600px;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, var(--color-black--30) 100%);
  }

  & > .imageLayout {
    height: 100%;
  }
}

.componentHeroContentBorder {
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: var(--color-white);

  & > .border {
    z-index: 1;
    width: 100%;
    height: var(--size-64);

    @media (--viewport-md) {
      max-width: var(--max-width-content);
    }
  }
}

.componentTitle {
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-4);
  padding-bottom: var(--size-28);
  text-align: center;

  @media (--viewport-md) {
    padding-bottom: var(--size-32);
  }

  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    width: 100%;
    bottom: -100%;
    aspect-ratio: 1;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 60%);
  }

  & > .title {
    max-width: var(--width-title);
  }
}

.title {
  text-transform: uppercase;
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-title);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-heading-condensed);
  letter-spacing: var(--letter-spacing-heading-xl);
}

.italic {
  text-transform: lowercase;
  font-family: var(--font-family-italic);
  font-size: var(--font-size-50-100);
}

.subtitle {
  text-transform: capitalize;
  font-family: var(--font-family-italic);
  font-size: var(--font-size-36-55);
}

.border {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-red-500);
  border-radius: var(--radius-15) var(--radius-15) 0 0;
  transform-origin: bottom center;

  & > .anchorLink {
    width: max-content;
  }
}

.anchorLink {
  transition: var(--duration-400) var(--ease);
  transition-property: opacity transform;

  &.isInViewport {
    opacity: 0;
    transform: translateY(var(--size-24));
    pointer-events: none;
  }

  & > .iconLayout {
    width: var(--size-20);
  }
}

.componentHeroContent {
  z-index: 0;
  position: relative;
  display: grid;
  justify-items: center;
  align-items: end;
  overflow: hidden;
  scroll-margin-top: var(--size-100);

  &::after {
    content: '';
    z-index: -1;
    grid-area: 1 / -1;
    width: 100%;
    height: 450px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, var(--theme-background-color) 100%);
  }

  & > .contentBlock {
    grid-area: 1 / -1;
    width: 100%;

    @media (--viewport-md) {
      max-width: var(--max-width-content);
    }
  }
}

.contentBlock {
  padding: var(--size-48) 0 var(--size-100);
  color: var(--color-white);
  background-color: var(--color-red-500);
  border-radius: 0 0 var(--radius-15) var(--radius-15);
  transform-origin: top center;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-40);

  & > .introduction {
    max-width: 800px;
  }
}

.introduction {
  text-align: center;
  font-family: var(--font-family-base);
  font-size: var(--font-size-20-25);
}

.componentCardDeck {
  z-index: 0;
  position: relative;
  display: grid;
  gap: var(--size-64);
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 500px var(--size-80);
  grid-template-areas:
    'deck            deck'
    'previousButton  nextButton';

  @media (--viewport-lg) {
    grid-template-columns: var(--size-80) 1fr var(--size-80);
    grid-template-rows: 600px;
    grid-template-areas: 'previousButton deck nextButton';
  }

  & > .cardDeck {
    z-index: 1;
    grid-area: deck;
  }

  & > .previousButtonLayout {
    grid-area: previousButton;
    justify-self: end;
    width: var(--size-80);
  }

  & > .nextButtonLayout {
    grid-area: nextButton;
    width: var(--size-80);
  }
}

.cardDeck {
  z-index: 0;
  position: relative;
  display: grid;
  justify-items: center;
  align-items: center;

  & > * {
    grid-area: 1 / -1;
  }

  & > .deckPosition {
    width: 100%;
  }

  & > .finalCardLayout {
    z-index: -1;
    height: 100%;

    @media (--viewport-md) {
      height: 70%;
    }
  }
}

.deckPosition {
  display: flex;
  justify-content: center;

  & > .card {
    max-width: 100%;
    height: inherit;

    @media (--viewport-md) {
      height: 500px;
    }
  }
}

.componentFinalCard {
  pointer-events: none;
  touch-action: none;
  opacity: 0;
  transform: scale(0.75);
  aspect-ratio: 5 / 7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-red-700);
  border-radius: var(--radius-20);
  padding: var(--size-48) var(--size-32) var(--size-32);
  transition:
    opacity var(--duration-400) var(--ease),
    transform var(--duration-400) var(--ease-out-expo);

  &.isActive {
    pointer-events: auto;
    opacity: 1;
    transform: scale(1);
    transition:
      opacity var(--duration-250) var(--ease),
      transform var(--duration-400) var(--ease-out-expo);
  }

  & > .buttonsContainer {
    width: 100%;
  }
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
}

.card {
  touch-action: pan-y;
  aspect-ratio: 4 / 5;
  color: var(--color-black);
  background-color: var(--color-white);
  border: 3px solid var(--color-black);
  border-radius: var(--radius-20);
  transform-origin: bottom center;
  user-select: none;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  & > .cardContentLayout {
    max-width: 100%;
    height: 100%;
  }
}

.componentCardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--size-20);
  padding: var(--size-48) var(--size-32) var(--size-32);

  & > .buttonLayout {
    width: 100%;
  }
}

.cardContentTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-20);
  padding: 0 var(--size-16);
}

.cardIndexText {
  font-family: var(--font-family-italic);
  font-size: var(--font-size-16-18);
}

.cardTitle {
  text-align: center;
  text-transform: uppercase;
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-48-55);
  line-height: var(--line-height-heading-condensed);
  letter-spacing: var(--letter-spacing-heading);

  &.hasLongWord {
    font-size: var(--font-size-34-42);
  }
}
