.component {
  background-color: var(--theme-background-color);
}

.formSection {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  padding: var(--size-40) 0;
  color: var(--color-black);
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  color: var(--theme-text-color);
}
