
.componentText,
.componentNumber {
  border-bottom: 1px solid var(--theme-accent-color);

  &:focus-visible {
    outline: none;
  }
}

.componentTextarea {
  & > .textArea {
    width: 100%;
    min-height: var(--size-48);
    max-height: 250px;
  }
}

.textArea {
  resize: vertical;
  border-bottom: 1px solid var(--theme-accent-color);

  &:focus-visible {
    outline: none;
  }
}

.componentSelect {
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--theme-accent-color);

  &:focus-within {
    outline: 1px blue;
  }

  & > .select {
    width: 100%;
  }

  & > .iconLayout {
    position: absolute;
    right: 0;
  }
}

.select {
  cursor: pointer;
  padding: var(--size-12) 0;
}

.componentCheckbox,
.componentCheckBoxWithValue {
  display: flex;
  align-items: center;
  gap: var(--size-8);
  padding-bottom: var(--size-8);

  & > .inputCheckbox {
    min-width: var(--size-24);
    width: var(--size-24);
    height: var(--size-24);
  }
}

.inputCheckbox {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--theme-accent-color);
  border-radius: var(--radius-6);
  cursor: pointer;

  &::after {
    content: '';
    opacity: 0;
    width: 100%;
    height: 100%;
    display: block;
    mask-image: url('/images/icons/check.svg');
    mask-position: center;
    background-color: var(--theme-accent-color);
  }

  &:checked {
    &::after {
      opacity: 1;
    }
  }
}

.labelCheckbox {
  font-family: var(--font-family-base);
  font-size: var(--font-size-13);
  font-weight: var(--font-weight-400);
  user-select: none;
}

.componentFile {
  z-index: 0;
  position: relative;

  & > .fileButton {
    width: 100%;
  }

  & > .formValidationLayout {
    z-index: -1;
    margin-top: calc(-1 * var(--size-8));
  }
}

.fileInputContainer {
  z-index: 0;
  position: relative;

  & > .fileInput {
    z-index: 1;
    position: absolute;
    inset: 0;
  }

  & > .fileInputButton {
    z-index: 0;
    position: relative;
    width: 100%;
  }
}

.fileInput {
  opacity: 0;
  cursor: pointer;
}

.fileButton,
.fileInputLabel {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: var(--size-12);
  padding: var(--size-12) 0;
  font-family: var(--font-family-base);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
  text-align: left;
  color: var(--theme-text-color);
  border-bottom: 1px solid var(--theme-accent-color);

  &:disabled {
    opacity: 0.7;
  }

  & > .icon {
    width: var(--size-16);
    height: var(--size-16);
  }
}
