.component {
  padding-block: var(--size-100);
  color: var(--theme-text-color);
  background-color: var(--theme-background-color);
}

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'image'
    'title'
    'contact';
  gap: var(--size-24);

  @media (--viewport-lg) {
    gap: var(--size-100);
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: 'title image contact';
  }

  & > .headingLayout {
    grid-area: title;
    max-width: 350px;
  }

  & > .imageLayout {
    grid-area: image;

    @media not (--viewport-lg) {
      max-width: var(--size-200);
    }
  }

  & > .contactInfoLayout {
    grid-area: contact;

    @media (--viewport-lg) {
      align-self: flex-end;
    }
  }
}

.componentContactInfo {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);

  @media (--viewport-lg) {
    align-items: flex-end;
    gap: var(--size-32);
  }

  & > .description {
    max-width: 300px;
  }
}

.description {
  font-family: var(--font-family-base);
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-text);

  @media (--viewport-lg) {
    padding-left: var(--size-20);
  }
}

.componentContactInfoList {
  display: flex;
  gap: var(--size-12);

  & > * {
    height: var(--size-64);
    width: var(--size-64);
  }
}

.componentContactInfoItem {
  aspect-ratio: 1;

  & > * {
    height: 100%;
    width: 100%;
  }
}

.contactInfoLink {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--theme-button-color);
  border-radius: var(--radius-15);

  & > .iconLayout {
    width: var(--size-28);
  }
}
