.component {
  & > .scrollBoxLayout {
    height: 200vh;

    @media (--viewport-md) {
      height: 250vh;
    }
  }
}

.componentScrollBox {
  padding-top: var(--size-100);
  text-align: center;
  position: relative;

  & > .heroContentLayout {
    top: 0;
  }

  & > .stickyImageLayout {
    top: 0;
  }
}

.imageWrapper {
  & > .imageLayout {
    height: 100lvh;
    width: 100vw;
    display: none;

    @media (--viewport-md) {
      display: flex;
    }
  }

  & > .imageMobileLayout {
    height: 100lvh;
    width: 100vw;
    display: flex;

    @media (--viewport-md) {
      display: none;
    }
  }
}

.componentAnimatedStickyContainer {
  position: sticky;
  display: flex;
  justify-content: center;
}

.componentHeroSubtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--theme-heading-color);
  font-family: var(--font-family-italic);
}
