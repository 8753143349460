.modalOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  transition: var(--duration-600) var(--ease);
  transition-property: background, backdrop-filter;
  pointer-events: none;

  &.isOpen {
    backdrop-filter: blur(10px);
    background: var(--color-black--30);
    pointer-events: auto;
  }
}

.component_rootWrapper {
  max-height: 80svh;
}

.componentWrapper {
  opacity: 0;
  padding: var(--size-24) var(--size-16) var(--size-64);
  border-radius: var(--radius-15);
  background-color: var(--theme-background-color);
  user-select: none;
  pointer-events: none;
  overflow-y: auto;

  @media (--viewport-md) {
    padding: var(--size-32) var(--size-24) var(--size-80);
  }

  &.isOpen {
    pointer-events: auto;
  }

  & > .closeButtonLayout {
    width: var(--size-40);
    height: var(--size-40);
  }
}
