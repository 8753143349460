.component {
  padding: var(--size-125) 0 var(--size-80);
  color: var(--theme-text-color);
  background-color: var(--theme-background-color);
}

.container {
  display: flex;
  flex-direction: column;
  gap: var(--size-48);

  @media (--viewport-md) {
    gap: var(--size-80);
  }
}

.componentCasesMobile {
  display: flex;
  flex-direction: column;
  gap: var(--size-48);

  @media (--viewport-lg) {
    display: none;
  }
}

.mobileContentContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);

  & > .imageCardLayout {
    height: 350px;
  }
}

.componentCasesDesktop {
  --items-length: 0;
  --height: 0;
  --height-card: calc(var(--height) / var(--items-length));

  position: relative;

  @media not (--viewport-lg) {
    display: none;
  }

  & > .casesContainer {
    height: var(--height);
  }
}

.casesContainer {
  & > .cardsContainer {
    top: calc((100vh - var(--height-card)) / 2);
  }
}

.cardsContainer {
  position: sticky;
}

.componentContentCards {
  z-index: 0;
  position: relative;

  & > .scrollIndicatorsContainer {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: var(--size-8);
    bottom: var(--size-125);
    left: 0;
  }
}

.scrollIndicatorsContainer {
  display: flex;
  justify-content: center;
  gap: var(--size-8);

  & > .scrollIndicator {
    width: var(--size-8);
    height: var(--size-8);

    &.isActive {
      width: var(--size-32);
    }
  }
}

.scrollIndicator {
  aspect-ratio: 1;
  border-radius: var(--radius-5);
  background-color: var(--color-black--20);
  transition: var(--duration-250) var(--ease);
  transition-property: width background-color;

  &.isActive {
    background-color: var(--color-black);
  }
}

.contentCardsContainer {
  display: grid;
  border-radius: var(--radius-15);
  background-color: var(--color-peach-500);

  & > * {
    grid-area: 1 / -1;
  }

  & > .contentLayout {
    min-height: 500px;
    height: var(--height);
    max-height: 80vh;
  }
}

.componentContentCard {
  --transition-interval: var(--duration-100);

  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: var(--size-32) 1fr var(--size-8) var(--size-52);
  grid-template-areas:
    'tags'
    'heading'
    'indicators'
    'button';
  gap: var(--size-32);
  padding: var(--size-32) var(--size-20);
  text-align: center;
  pointer-events: none;
  border-radius: var(--radius-15);
  background-color: var(--color-peach-500);

  @media (--viewport-lg) {
    background-color: transparent;
    padding: var(--size-40);
  }

  & > .tagsContainer {
    grid-area: tags;
  }

  & > .headingContainer {
    grid-area: heading;
    max-width: 400px;
  }

  & > .buttonContainer {
    grid-area: button;
    width: 100%;
  }
}

.tagsContainer {
  --transition-delay: calc(var(--duration-250) + var(--transition-interval) * 0);

  display: flex;
  flex-wrap: wrap;
  gap: var(--size-8);
  transition: opacity var(--duration-400) var(--ease);

  & > .tagLayout {
    width: max-content;
    height: max-content;
  }
}

.headingContainer {
  --transition-delay: calc(var(--duration-250) + var(--transition-interval) * 1);
}

.buttonContainer {
  --transition-delay: calc(var(--duration-250) + var(--transition-interval) * 2);

  & > .buttonLayout {
    width: 100%;
  }
}

.transition {
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--duration-400) var(--ease);

  &.isActive {
    opacity: 1;
    pointer-events: auto;
    transition-delay: var(--transition-delay);
  }

  @media not (--viewport-lg) {
    opacity: 1;
    pointer-events: auto;
  }
}

.componentImageCards {
  & > .imagesContainer {
    width: 100%;
    min-height: 500px;
    height: var(--height);
    max-height: 80vh;
    top: 0;
  }
}

.imagesContainer {
  display: grid;
  align-items: end;
  border-radius: var(--radius-15);
  overflow: hidden;

  & > * {
    grid-area: 1 / -1;
  }

  & > .imageCardLayout {
    width: 100%;
    height: 100%;
  }
}

.componentImageCard {
  clip-path: inset(0 round var(--radius-15));

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}
