.component {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--size-16);
  padding: var(--size-16) var(--size-32);
  border-radius: var(--radius-10);
  color: var(--color-white);
  background-color: var(--color-black);

  & > .iconLayout {
    width: var(--size-24);
  }
}
