.componentBase {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
  padding: var(--size-64) var(--size-80) var(--size-52);
  color: var(--theme-text-color);
  background-color: var(--theme-background-color);
  border: 2px solid var(--color-grey-200);
  border-radius: var(--radius-15);

  & > .resetButtonLayout {
    width: max-content;
    margin-top: var(--size-16);
  }
}
