.component {
  z-index: 0;
  position: relative;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-black);
  background-color: var(--color-white);
  border-radius: 50%;
  border: 2px solid var(--color-black);
  transition: transform var(--duration-250) var(--ease-out-circ);

  &:hover,
  &:focus-visible {
    --transform-shadow: 6px, 6px;
    --opacity-shadow: 1;

    transform: translate(-3px, -3px) scale(1.0005);
  }

  &:active {
    --transform-shadow: 0, 0;
    --opacity-shadow: 0;

    transform: translate(0, 0);
    transition-duration: var(--duration-100);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;
  }

  &::before {
    z-index: -1;
    background-color: var(--color-white);
  }

  &::after {
    opacity: var(--opacity-shadow);
    z-index: -2;
    background-color: var(--color-black);
    transform: translate(var(--transform-shadow));
    transition:
      opacity var(--duration-100) var(--ease),
      transform var(--duration-250) var(--ease-out-circ);
  }

  & > .iconLayout {
    width: var(--size-20);
  }
}
