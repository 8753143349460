.component,
.componentWithCareerCoachTooltip {
  color: var(--theme-text-color);

  & > .titleLayout {
    margin-bottom: var(--size-24);
  }

  & > .portableTextLayout {
    margin-bottom: var(--size-52);
  }
}

.componentBenefitsList {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  justify-content: space-between;

  & > * {
    @media not (--viewport-sm) {
      width: var(--size-175);
    }
  }

  & > :nth-child(even) {
    @media not (--viewport-sm) {
      align-self: flex-end;
    }
  }

  @media (--viewport-sm) {
    display: grid;
    gap: var(--size-32) var(--size-64);
    grid-template-columns: repeat(2, minmax(auto, var(--size-175)));
  }

  @media (--viewport-md) {
    gap: var(--size-52) var(--size-80);
    grid-template-columns: repeat(3, minmax(auto, var(--size-175)));
  }

  @media (--viewport-lg) {
    gap: var(--size-32) var(--size-64);
    grid-template-columns: repeat(2, minmax(auto, var(--size-175)));
  }

  @media (--viewport-xl) {
    gap: var(--size-52) var(--size-80);
    grid-template-columns: repeat(3, minmax(auto, var(--size-175)));
  }
}

.componentBenefit {
  display: flex;
  flex-direction: column;
  gap: var(--size-8);

  & > .logoLayout {
    flex: 1;
    width: 100%;
  }
}

.illustration {
  position: relative;
  display: inline-block;
  aspect-ratio: 1;
  mask-size: 100% auto;
  mask-position: center;
  mask-repeat: no-repeat;
  background-color: var(--theme-text-color);
}

.description {
  color: var(--theme-text-color);
}
