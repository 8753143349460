.component {
  overflow-y: auto;

  & > .container {
    min-height: 100%;
    height: max-content;
  }
}

.container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: var(--size-40) 1fr;
  justify-items: center;
  align-content: center;
  gap: var(--size-24);
  padding: var(--size-48) var(--size-20) var(--size-80) var(--size-32);
  color: var(--color-white);
  text-align: center;
  overflow: hidden;

  @media (--viewport-md) {
    padding: var(--size-64) var(--size-24) var(--size-64) var(--size-32);
  }

  & > * {
    align-self: center;
  }

  & > .progressBarLayout {
    position: absolute;
    width: 100%;
    height: var(--size-8);
    top: 0;
  }

  & > .formSeekButtonsLayout {
    position: absolute;
    top: var(--size-20);
    left: var(--size-16);
  }

  & > .closeButtonLayout {
    grid-area: 1;
    align-self: start;
    width: var(--size-40);
    height: var(--size-40);
  }

  & > .stepLayout {
    width: 100%;
    grid-area: 2;
  }
}

.componentProgressBar {
  --progress: 0%;

  pointer-events: none;
  background-color: var(--color-white);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform var(--duration-600) var(--ease);

  &.isActive {
    transform: scaleX(var(--progress));
    transform-origin: left;
    transition-duration: var(--duration-400);
  }
}

.componentFormSeekButtons {
  pointer-events: none;
  display: flex;
  gap: var(--size-8);

  & > .buttonLayout {
    width: var(--size-20);
    height: var(--size-20);
  }
}

.componentFormSeekButton {
  pointer-events: none;
  opacity: 0;
  transform: scale(0.5);
  transition: var(--duration-250) var(--ease);
  transition-property: opacity, transform;

  &.isActive {
    pointer-events: auto;
    opacity: 1;
    transform: scale(1);
  }

  & > .buttonIconLayout {
    width: 100%;
    height: 100%;
  }
}

.componentStart {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-16);
  padding-bottom: var(--size-32);

  &.goingToNextStep {
    pointer-events: none;
  }

  & > .startSubtitleContainer {
    width: 100%;
  }

  & > .logo {
    max-width: 200px;
    margin-bottom: var(--size-12);

    @media (--viewport-md) {
      max-width: 275px;
    }
  }

  & > .description {
    width: 100%;
    max-width: 250px;
  }

  & > .disclaimerButton {
    width: 100%;
    max-width: 350px;
    margin-top: var(--size-8);

    @media (--viewport-md) {
      margin-top: var(--size-12);
    }
  }

  & > .buttonContainer {
    width: 100%;
    margin-top: var(--size-12);
  }
}

.startSubtitleContainer,
.logo,
.description {
  transition: var(--duration-250) var(--ease);
  transition-property: opacity, transform;

  &.goingToNextStep {
    opacity: 0;
    transform: scale(0.9);
  }
}

.startSubtitleContainer {
  & > .subtitleLayout {
    width: 100%;
  }
}

.logo {
  transition-delay: var(--duration-100);
}

.description {
  text-align: center;
  font-family: var(--font-family-base);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  transition-delay: var(--duration-100);
}

.buttonContainer {
  transition: opacity var(--duration-250) var(--ease);

  &.goingToNextStep {
    opacity: 0;
  }

  & > .buttonLayout {
    width: 100%;
  }
}

.disclaimerButton {
  transition: opacity var(--duration-250) var(--ease);

  &.goingToNextStep {
    opacity: 0;
  }
}

.componentDisclaimer {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
  padding: var(--size-32);
  border-radius: var(--radius-20);
  color: var(--color-black);
  background-color: var(--color-white);
  border: 3px solid var(--color-black);
  text-align: left;
  transition: var(--duration-250) var(--ease);
  transition-property: opacity, transform;
  animation: stepEnterAnimation var(--duration-400) var(--ease);

  &.goingToNextStep {
    pointer-events: none;
    opacity: 0;
    transform: scale(0.95);
  }
}

.componentForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-12);
  padding-bottom: var(--size-64);
  animation: stepEnterAnimation var(--duration-400) var(--ease);
}

.formQuestionsList {
  display: grid;

  & > * {
    grid-area: 1 / -1;
  }
}

.componentQuestion {
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-24);

  &.isCurrent {
    pointer-events: var(--pointer-events, none);
  }

  & > .questionContainer {
    max-width: 450px;
  }

  & > .answersList {
    width: 100%;
    max-width: 350px;
  }
}

.subtitleContainer {
  opacity: 0;
  transform: scale(0.95);
  transition: var(--duration-400) var(--ease);
  transition-property: opacity, transform;

  &.isCurrent {
    opacity: 1;
    transform: scale(1);
    transition-delay: var(--duration-250);
  }
}

.questionContainer {
  opacity: 0;
  transform: scale(0.95);
  transition: var(--duration-250) var(--ease);
  transition-property: opacity, transform;
  transition-delay: var(--duration-100);

  &.isCurrent {
    opacity: 1;
    transform: scale(1);
    transition-delay: var(--duration-400);
  }
}

.answersList {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-12);

  & > .answer {
    width: 100%;
  }
}

.answer {
  --index: 0;

  opacity: 0;
  transform: translateX(150%);
  transition: var(--duration-400) var(--ease);
  transition-property: opacity, transform;
  transition-delay: calc(var(--index) * var(--duration-100));

  &.isCurrent {
    opacity: 1;
    transform: translateX(0%);
    transition-delay: calc(var(--duration-250) + var(--index) * var(--duration-100));
  }

  &.isPrevious {
    opacity: 0;
    transform: translateX(-150%);
  }

  & > .radioButtonLayout {
    width: 100%;
  }
}

.componentRadioButton {
  --transform-shadow: 0, 0;

  z-index: 0;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--size-16);
  padding: var(--size-24) var(--size-12) var(--size-24) var(--size-20);
  border: 2px solid var(--color-black);
  border-radius: var(--radius-10);
  color: var(--color-black);
  background-color: var(--color-white);
  transition: transform var(--duration-250) var(--ease-out-circ);

  @media (--viewport-md) {
    gap: var(--size-32);
    padding: var(--size-28) var(--size-12) var(--size-28) var(--size-28);
  }

  &:hover,
  &:focus-visible {
    --transform-shadow: 6px, 6px;

    transform: translate(-3px, -3px) scale(1.0005);
  }

  &:active,
  &.checked {
    --transform-shadow: 0, 0;

    transform: translate(0, 0);
    transition-duration: var(--duration-100);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: var(--radius-10);
  }

  &::before {
    z-index: -1;
    background-color: var(--color-white);
  }

  &::after {
    z-index: -2;
    background-color: var(--color-black);
    transform: translate(var(--transform-shadow));
    transition: transform var(--duration-250) var(--ease-out-circ);
  }

  & > .checkboxLayout {
    min-width: var(--size-40);
    width: var(--size-40);
    height: var(--size-40);
  }

  & > .input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  & > .label {
    max-width: var(--size-200);
  }
}

.input {
  cursor: pointer;
}

.componentCheckbox {
  --opacity-icon: 0;

  aspect-ratio: 1;
  z-index: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid var(--color-grey-200);
  transition: var(--duration-400) var(--ease-out-circ);
  transition-property: background-color, border-color;
  overflow: hidden;

  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    display: block;
    inset: 0;
    border-radius: 50%;
    background-color: var(--color-black);
    transform: scale(0);
    transition: transform var(--duration-250) var(--ease-out-circ);
  }

  &.checked {
    --opacity-icon: 1;

    background-color: var(--color-black);
    border-color: var(--color-black);

    &::after {
      transform: scale(1.1);
    }
  }

  & > .iconContainer {
    z-index: 1;
    width: 100%;
    height: 100%;
  }
}

.iconContainer {
  opacity: var(--opacity-icon);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  transform: scale(1.3);
}

.label {
  font-family: var(--font-family-base);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-700);
  text-align: left;
}

.componentLoadingStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-16);
  animation: stepEnterAnimation var(--duration-400) var(--ease);

  & > .headingLayout {
    max-width: 300px;
  }

  & > .loadBar {
    width: var(--size-150);
    height: var(--size-4);
  }
}

.loadBar {
  overflow: hidden;
  border-radius: var(--radius-5);
  background-color: var(--color-white--30);

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    transform: scaleX(0);
    transform-origin: left;
    animation: loading var(--duration-600) var(--ease);
    animation-fill-mode: forwards;
  }
}

.componentAdvice {
  padding: var(--size-32);
  color: var(--color-black);
  background-color: var(--color-white);
  border: 3px solid var(--color-black);
  border-radius: var(--radius-20);
  text-align: left;
  animation: stepEnterAnimation var(--duration-400) var(--ease);

  & > .subtitleLayout {
    width: 100%;
  }

  & > .buttonLayout {
    width: 100%;
  }
}

.componentMarkDown {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    width: 100%;
    margin-bottom: var(--size-16);

    &:has(+ .adviceHeading) {
      margin-bottom: var(--size-100);
    }
  }

  & > .adviceHeading {
    margin-top: var(--size-12);
    margin-bottom: var(--size-32);
  }

  & > .adviceSubheading {
    margin-top: var(--size-40);
    margin-bottom: var(--size-16);
  }

  & > .adviceParagraph {
    margin-top: var(--size-4);
    margin-bottom: var(--size-12);
  }

  & > .imageLayout {
    width: 75%;
    max-width: 350px;
  }
}

.adviceHeading {
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-48);
  line-height: var(--line-height-heading-condensed);
  letter-spacing: var(--letter-spacing-heading);
  text-transform: uppercase;
  text-align: center;
}

.adviceSubheading {
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-40);
  line-height: var(--line-height-heading-condensed);
  letter-spacing: var(--letter-spacing-subheading);
  text-transform: uppercase;
}

.adviceParagraph {
  font-family: var(--font-family-base);
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-400);
}

.componentSubtitle {
  display: inline-block;
  font-family: var(--font-family-italic);
  font-size: var(--font-size-18);
  text-align: center;
}

.componentEmailForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-48);
  padding-bottom: var(--size-150);

  & > .headingLayout {
    max-width: 300px;
  }

  & > .emailForm {
    width: 100%;
  }
}

.emailForm {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
  padding: var(--size-32);
  color: var(--color-black);
  background-color: var(--theme-background-color);
  border: 3px solid var(--color-black);
  border-radius: var(--radius-20);
  text-align: left;

  & > .submitButtonLayout {
    margin-top: var(--size-24);
  }
}

.componentErrorMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-12);

  & > .headingLayout {
    max-width: 300px;
  }

  & > .buttonLayout {
    margin-top: var(--size-16);
  }
}

.componentGeneratedAdvice {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
}

.componentRelatedContent {
  display: flex;
  flex-direction: column;
  gap: var(--size-20);
}

.componentDisclaimerButton {
  font-size: var(--font-size-12);
  font-style: italic;
}

.componentDisclaimerContent {
  padding-top: var(--size-40);

  & > * {
    margin-bottom: var(--size-4);

    &:has(+ .headingLayout) {
      margin-bottom: var(--size-24);
    }
  }
}

.disclaimerLink {
  font-family: var(--font-family-base);
  font-size: var(--font-size-12);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.componentDisclaimerHeading {
  font-family: var(--font-family-base);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-700);
}

.componentDisclaimerText {
  font-family: var(--font-family-base);
  font-size: var(--font-size-12);
}

@keyframes stepEnterAnimation {
  from {
    transform: translateY(var(--size-24));
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes loading {
  from { transform: scaleX(0); }
  to { transform: scaleX(1); }
}
