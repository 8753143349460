.component {
  color: var(--theme-text-color);
  background-color: var(--theme-background-color);
  padding-block: var(--size-48);

  @media (--viewport-md) {
    padding-block: var(--size-80);
  }
}

.componentAccordionItem {
  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-black);
  }

  & > .buttonLayout {
    width: 100%;
  }
}

.componentItemButton {
  --icon-rotation: 0deg;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-32) 0;
  font-size: var(--font-size-22);
  font-weight: var(--font-weight-700);
  text-align: left;
  transition: padding var(--duration-250) var(--ease-out-expo);

  &.isActive {
    --icon-rotation: -180deg;

    padding: var(--size-32) 0 var(--size-20);
  }

  & > .iconContainer {
    width: var(--size-20);
    height: var(--size-20);
  }
}

.questionTitle {
  padding-right: var(--size-24);
}

.iconContainer {
  transform: rotateX(var(--icon-rotation));
  transition: transform var(--duration-400) var(--ease-out-expo);

  & > .iconLayout {
    width: 100%;
    height: 100%;
  }
}

.componentItemContent {
  pointer-events: none;
  display: grid;
  grid-template-rows: 0fr;
  padding-bottom: 0;
  overflow: hidden;
  transition: var(--duration-250) var(--ease-out-expo);
  transition-property: grid-template-rows, padding;

  &.isActive {
    pointer-events: auto;
    grid-template-rows: 1fr;
    padding-bottom: var(--size-20);
  }

  & > * {
    min-height: 0;
  }
}

.contentText {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-text);
}
