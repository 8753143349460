.componentBase {
  & > .paginationLayout {
    margin-bottom: var(--size-40);
  }

  & > .emptyStateLayout {
    margin-block: var(--size-64);
  }
}

.componentListBase {
  display: flex;
  flex-direction: column;
  gap: var(--size-20);
  padding: var(--size-80) 0 var(--size-40);
}
