.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-48);
  color: var(--theme-text-color);
  background-color: var(--theme-background-color);
}

.componentSlider {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
}

.slider {
  --slide-opacity: 0;
  --slide-translateX: var(--size-175);

  display: flex;
  cursor: grab;

  &.isVisible {
    --slide-opacity: 1;
    --slide-translateX: 0;

    overflow: visible;
  }

  &:active {
    cursor: grabbing;
  }

  & > * {
    width: 100%;
  }

  & > .slideContainer {
    min-width: max-content;
    max-width: max-content;
  }
}

.slideContainer {
  overflow: visible !important;

  & > .slideWrapper {
    height: 100%;
  }
}

.slideWrapper {
  --index: 0;

  opacity: var(--slide-opacity);
  transform: translateX(var(--slide-translateX));
  transition:
    opacity var(--duration-400) var(--ease),
    transform var(--duration-600) var(--ease);
  transition-delay: calc(var(--index) * var(--duration-100));

  & > * {
    height: 100%;
  }
}
