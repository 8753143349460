.componentHero {
  padding: var(--size-125) 0 var(--size-40);
  color: white;
  background-color: var(--color-black);

  @media (--viewport-md) {
    padding-top: var(--size-200);
  }
}

.componentHeading {
  display: flex;
  align-items: flex-end;
  text-align: left;
}

.componentHeroCard {
  display: flex;
  align-items: flex-end;

  & > .heroCardContainer {
    width: 100%;

    @media (--viewport-md) {
      max-width: 400px;
    }

    @media (--viewport-lg) {
      margin-bottom: calc(-1 * var(--size-100));
    }
  }
}

.heroCardContainer {
  padding: var(--size-28);
  background: var(--dynamic-background-color, var(--color-red-500));

  @media (--viewport-lg) {
    opacity: 0;
    animation: enterCard var(--duration-600) var(--ease) forwards;
    animation-delay: var(--duration-250);
  }

  & > .buttonLayout {
    width: 100%;
    margin-top: var(--size-28);
  }
}

.componentImageWithLogo {
  z-index: 0;
  position: relative;
  display: grid;
  place-items: center;

  &::before {
    content: '';
    z-index: 1;
    position: absolute;
    inset: 0;
    opacity: 0.2;
    background-color: var(--color-black);
  }

  & > * {
    grid-area: 1 / -1;
  }

  & > .logoContainer {
    z-index: 2;
    max-width: 50%;
  }
}

.logoContainer {
  padding: var(--size-32) 0;

  & > .logoLayout {
    width: 100%;
  }
}

@keyframes enterCard {
  from {
    opacity: 0;
    transform: translateY(25%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
