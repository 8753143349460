.component {
  z-index: 0;
  position: relative;
  overflow: hidden !important;
  pointer-events: none;
  user-select: none;
  display: grid;
  background: transparent;
  transition: background var(--duration-600) var(--ease);

  &.isDrawerOpen {
    pointer-events: auto;
    background: var(--color-black--30);

    & > .thumbGrabberLayout {
      @media (pointer: coarse) {
        width: var(--size-32);
      }
    }
  }

  & > * {
    grid-area: 1 / -1;
  }

  & > .thumbGrabberLayout {
    z-index: 1;
    justify-self: end;
    align-self: center;
    width: var(--size-32);
    height: var(--size-150);

    @media (pointer: coarse) {
      width: var(--size-40);
    }
  }
}

.componentThumbGrabber {
  --background-color-thumbGrabber: var(--color-red-500);
  --background-color-tooltip: var(--color-red-500);
  --color-tooltip: var(--color-white);
  --opacity-tooltip: 1;

  pointer-events: none;
  touch-action: pan-x;
  position: relative;
  display: flex;
  align-items: center;

  &.isWhite {
    --background-color-thumbGrabber: var(--color-white);
    --background-color-tooltip: var(--color-white);
    --color-tooltip: var(--color-red-500);
  }

  &.isDrawerOpen {
    --background-color-thumbGrabber: var(--color-white);
    --background-color-tooltip: var(--color-red-500);
    --color-tooltip: var(--color-white);
  }

  &:active {
    cursor: grabbing;
  }

  & > .thumbGrabber {
    width: 100%;
    height: 100%;
  }

  & > .tooltipLayout {
    position: absolute;
    width: max-content;
    max-width: calc(100vw - var(--size-100));
    right: var(--size-40);
  }
}

.thumbGrabber {
  pointer-events: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-20);
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  &::after {
    content: '';
    width: var(--size-8);
    height: var(--size-125);
    border-radius: var(--radius-5);
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.12);
    background-color: var(--background-color-thumbGrabber);
    transition: background-color var(--duration-250) var(--ease);
  }
}

.component_rootDrawer {
  display: none;
  pointer-events: none;
  position: fixed;
  width: 100vw;
  max-width: 100%;
  height: 100lvh;
  left: 0;
  top: 0;

  &.isVisible {
    display: grid;

    & > * {
      grid-area: 1 / -1;
    }
  }

  & > .drawer {
    justify-self: end;
    width: calc(100vw - var(--size-32));
    height: 100%;

    @media (--viewport-md) {
      width: 600px;
    }
  }
}

.drawer {
  pointer-events: auto;
  position: relative;
  transform: translateX(100%);
  background-color: var(--color-red-500);

  &::after {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100%;
    right: -100vw;
    top: 0;
    background-color: var(--color-red-500);
  }

  & > .questionnaireLayout {
    width: 100%;
    height: 100vh;
  }
}
