.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
  padding: var(--size-80) 0;
  background-color: var(--theme-background-color);
  overflow: hidden;

  @media (--viewport-md) {
    padding: var(--size-150) 0 var(--size-100);
  }
}

.sliderContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
}

.slider {
  --slide-opacity: 0;
  --slide-translateX: var(--size-175);

  display: flex;
  cursor: grab;

  &.isVisible {
    --slide-opacity: 1;
    --slide-translateX: 0;

    overflow: visible;
  }

  &:active {
    cursor: grabbing;
  }

  & > .slideContainer {
    height: auto;
    max-width: max-content;
    min-width: max-content;
  }
}

.slideContainer {
  opacity: var(--slide-opacity);
  transition: opacity var(--duration-400) var(--ease);

  &:not(:last-of-type) {
    border-right: 1px solid color-mix(in srgb, var(--theme-text-color) 30%, var(--theme-background-color));
  }
}

.slideWrapper {
  --index: 0;
  --slide-content-opacity: 0.3;

  opacity: var(--slide-opacity);
  transform: translateX(var(--slide-translateX));
  transition:
    opacity var(--duration-400) var(--ease),
    transform var(--duration-600) var(--ease);
  transition-delay: calc(var(--index) * var(--duration-100));

  &.isActive {
    --slide-content-opacity: 1;
  }

  & > .cardLayout {
    width: max-content;
    height: 100%;
  }
}

.componentNumberCard {
  opacity: var(--slide-content-opacity);
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
  padding-right: var(--size-80);
  aspect-ratio: 1;
  transition: opacity var(--duration-250) var(--ease);

  & > .description {
    max-width: 250px;
  }
}

.number {
  display: flex;
  align-items: center;
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-150-320);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-heading-condensed);
  color: var(--theme-text-color);
}

.plusIcon {
  font-size: var(--font-size-60-120);
}

.description {
  font-family: var(--font-family-base);
  font-size: var(--font-size-22);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-paragraph);
  color: var(--theme-text-color);
}
