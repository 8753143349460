.componentBase {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-8);

  & > .tagLayout {
    width: max-content;
    height: max-content;
  }
}

.componentCentered {
  justify-content: center;
}
