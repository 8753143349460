.page {
  z-index: 0;
  padding-top: var(--size-100);
  position: relative;

  & > .formLayout {
    margin-top: var(--size-32);
  }

  & > .recruiterLayout {
    margin-block: var(--size-64);
  }

  & > .stickyBarLayout {
    z-index: 1;
    width: max-content;
    max-width: 100%;
    top: var(--size-12);
    margin: 0 auto;
  }
}
