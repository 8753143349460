.component,
.componentSection {
  overflow: hidden;
  font-family: var(--font-family-base);
  color: var(--theme-text-color);

  & > * {
    margin-bottom: var(--size-16);

    &:has(+ .headingLayout) {
      margin-bottom: var(--size-100);
    }

    &:has(+ .subheadingLayout) {
      margin-bottom: var(--size-64);
    }
  }

  & > .headingLayout {
    margin-top: var(--size-40);
    margin-bottom: var(--size-20);

    &:first-child {
      margin-top: var(--size-64);
    }
  }

  & > .subheadingLayout {
    margin-top: var(--size-32);
    margin-bottom: var(--size-8);
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.paragraph {
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-300);
}

.listOrdered {
  padding-left: var(--size-20);
  font-family: var(--font-family-base);
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-text);
  list-style-type: decimal;
  list-style-position: outside;
}

.listUnordered {
  padding-left: var(--size-20);
  font-family: var(--font-family-base);
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-text);
  list-style-type: disc;
  list-style-position: outside;
}
