.component {
  padding: var(--size-80) 0 var(--size-125);
  background-color: var(--theme-background-color);
  overflow: hidden;
}

.componentGrid {
  display: none;
  flex-direction: column;
  gap: var(--size-48);

  @media (--viewport-md) {
    display: flex;
  }
}

.sliderContainer {
  @media (--viewport-md) {
    display: none;
  }
}

.grid {
  display: grid;
  gap: var(--size-16);
  grid-template-columns: 1fr 1fr;

  @media (--viewport-lg) {
    gap: var(--size-16);
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--viewport-xl) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.componentSlider {
  --width-tags: min-content;
}

.component_rootCard {
  width: 100%;
  min-width: 275px;
}

.componentCard {
  --transition-delay: 0s;
  --color-logo: var(--color-peach-500);
  --background-color-tag: var(--color-white--10);

  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: var(--size-24);
  aspect-ratio: 3 / 4;
  padding: var(--size-80) var(--size-40) var(--size-40);
  border-radius: var(--radius-15);
  background-color: var(--color-black);
  overflow: hidden;

  &:hover {
    --transition-delay: var(--duration-100);
    --color-logo: var(--dynamic-text-color, var(--color-white));
    --background-color-tag: var(--color-black);

    &::after {
      transform: translateY(0%) scale(1.025);
    }
  }

  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    inset: 0;
    border-radius: var(--radius-15) var(--radius-15) 0 0;
    background-color: var(--dynamic-background-color, var(--color-red-500));
    transform: translateY(100%) scale(0.995);
    transition: transform var(--duration-400) var(--ease-out-expo);
    transition-delay: var(--transition-delay);
  }

  & > .logoLayout {
    flex: 1;
    max-width: 230px;

    @media not (--viewport-sm) {
      width: 90%;
    }

    @media (--viewport-sm) {
      min-width: 150px;
    }
  }
}

.componentLogo {
  position: relative;
  display: inline-block;
  mask-size: 100% auto;
  mask-position: top center;
  mask-repeat: no-repeat;
  background-color: var(--color-logo);
  transition: background-color var(--duration-250) var(--ease);
  transition-delay: var(--transition-delay);
}

.componentTags {
  & > .tagsContainer {
    width: var(--width-tags, auto);
  }
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--size-4);

  & > .tagLayout {
    width: max-content;
    height: max-content;
  }
}

.componentTag {
  padding: var(--size-8) var(--size-16);
  border-radius: var(--radius-20);
  font-family: var(--font-family-base);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
  color: var(--color-white);
  background-color: var(--background-color-tag);
  transition: background-color var(--duration-250) var(--ease);
}
