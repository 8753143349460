.component_rootCard {
  width: 275px;
}

.componentCard {
  position: relative;
  aspect-ratio: 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: var(--size-24);
  padding: var(--size-32) var(--size-40);
  color: var(--color-black);
  background-color: var(--color-peach-500);
  border-radius: var(--radius-15);
  transition: transform var(--duration-250) var(--ease);
  overflow: hidden;

  &:hover {
    transform: scale(1.04);
  }

  & > .anchor {
    position: static;
  }

  & > .imageLayout {
    width: 100%;
  }
}

.anchor {
  position: relative;
  text-align: center;
  text-transform: uppercase;
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-30);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-heading-condensed);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.moreInformation {
  font-family: var(--font-family-base);
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-normal);
}
