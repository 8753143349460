.component_root {
  width: 100vw;
  max-width: 100%;
  overflow: hidden;
}

.component {
  pointer-events: none;
  position: relative;
  background: transparent;
  transition: background var(--duration-600) var(--ease);
  color: var(--theme-accent-color);

  &.isOpen {
    pointer-events: auto;
    background: var(--color-black--30);
  }

  & > .drawerContainer {
    position: fixed;
    width: 100%;
    height: 100vh;
    right: 0;

    @media (--viewport-md) {
      width: max-content;
      min-width: 420px;
    }
  }
}

.drawerContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-48);
  padding: var(--size-48) var(--size-52) var(--size-80);
  background-color: var(--theme-background-color);
  overflow-y: scroll;
}

.checkboxGroupContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
}

.drawerActionsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--theme-accent-color);

  & > .closeButtonLayout {
    width: var(--size-40);
    height: var(--size-40);
  }
}

.componentCheckboxGroup {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
}

.componentFilterGroupTitle {
  color: var(--theme-accent-color);
  font-family: var(--font-family-base);
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-700);
  user-select: none;
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-8);

  & > .checkboxLayout {
    width: 100%;
  }
}

.componentCheckbox {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--size-12);
  padding: var(--size-8) var(--size-16);
  color: var(--theme-accent-color);
  border: 1px solid var(--theme-accent-color);
  border-radius: var(--radius-15);
  transition: var(--duration-250) var(--ease);
  transition-property: background-color, color;

  &.isChecked {
    color: var(--theme-menu-color);
    background-color: var(--theme-accent-color);
  }

  & > .checkboxInput {
    position: static;
    width: var(--size-24);
    height: var(--size-24);
  }
}

.checkboxLabel {
  font-family: var(--font-family-base);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  user-select: none;
}

.checkboxInput {
  position: relative;
  background-color: var(--theme-background-color);
  border: 2px solid var(--theme-accent-color-transparent);
  border-radius: var(--radius-5);
  transition: var(--duration-250) var(--ease);
  transition-property: background-color, border-color;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }

  &::after {
    content: '';
    opacity: 0;
    width: 100%;
    height: 100%;
    display: block;
    mask-image: url('/images/icons/check.svg');
    mask-position: center;
    background-color: var(--theme-accent-color);
  }

  &.isChecked {
    border-color: var(--theme-accent-color);

    &::after {
      opacity: 1;
    }
  }
}
