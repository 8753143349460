.component {
  background-color: var(--theme-background-color);
  padding: var(--size-80) 0;
}

.componentTitle {
  color: var(--theme-text-color);
}

.componentDescription {
  font-family: var(--font-family-base);
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-300);
  color: var(--theme-text-color);
}
