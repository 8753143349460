.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-40);

  @media (--viewport-lg) {
    position: relative;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 0;
  }

  & > .contentContainer {
    @media (--viewport-lg) {
      grid-column: 6 / 13;
    }
  }

  & > .asideContainer {
    @media (--viewport-lg) {
      grid-column: 1 / 6;
      align-self: flex-end;
      max-width: 100%;
      height: fit-content;
      left: 0;
      bottom: var(--size-125);
      overflow: hidden;
    }
  }
}

.asideContainer {
  position: sticky;
  display: block;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-80);
}
