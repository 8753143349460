.component {
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--size-12) var(--size-32);
  flex-wrap: wrap;
  padding: var(--size-8);
  background-color: var(--color-white);
  border: 1px solid var(--color-black);
  border-radius: var(--radius-15);
  transition: transform var(--duration-400) var(--ease-out-quad);

  @media (--viewport-lg) {
    justify-content: space-between;
    padding-left: var(--size-20);
  }

  &.isScrolledDown {
    transform: translateY(0);
  }

  &.isScrolledUp {
    transform: translateY(var(--size-100));
  }

  & > .buttonLayout {
    width: 100%;

    @media (--viewport-lg) {
      width: max-content;
    }
  }
}

.metaTagsContainer {
  @media not (--viewport-lg) {
    display: none;
  }
}
