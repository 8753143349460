.component {
  z-index: 0;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: 1fr;
  transform-origin: right center;
  pointer-events: none;
  cursor: pointer;

  &:active {
    cursor: grabbing;
  }

  &.isActive {
    pointer-events: auto;
  }

  & > .labelContainer,
  & > .anchorPoint {
    height: 100%;
  }

  & > .labelContainer {
    z-index: -1;
    margin-right: calc(-1 * var(--size-16));
  }
}

.anchorPoint {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  padding: 0 var(--size-12);
  border-radius: var(--radius-20);
  background-color: var(--background-color-tooltip);
  transition: background-color var(--duration-250) var(--ease);

  &::after {
    content: '';
    display: block;
    width: var(--size-8);
    height: var(--size-8);
    background-color: var(--color-tooltip);
    transition: background-color var(--duration-250) var(--ease);
    border-radius: 50%;
  }
}

.labelContainer {
  padding: var(--size-8) var(--size-16);
  clip-path: xywh(100% 0 0% 100% round var(--radius-20) 0 0 var(--radius-20));
  background-color: var(--background-color-tooltip);
  transition: background-color var(--duration-250) var(--ease);
}

.label {
  opacity: 1;
  white-space: nowrap;
  color: var(--color-tooltip);
  font-family: var(--font-family-italic);
  font-size: var(--font-size-16);
  transition: color var(--duration-250) var(--ease);
}
