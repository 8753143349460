.componentHero {
  padding: var(--size-200) 0 var(--size-28);
  background-color: var(--color-peach-500);
  color: var(--color-red-500);

  @media (--viewport-md) {
    padding: 300px 0 var(--size-40);
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: var(--size-64);
  padding: var(--size-100) 0;
}

.componentEventsList {
  display: flex;
  flex-direction: column;
  gap: var(--size-20);
}

.componentTitle {
  z-index: 0;
  position: relative;
  display: grid;

  & > * {
    grid-area: 1 / -1;
  }
}

.heroContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);

  & > .titleLayout {
    width: 100%;
    margin: 0 auto;
  }
}
