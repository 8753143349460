.component {
  overflow: hidden;
}

.component_rootCard,
.component_rootCardAnchor {
  width: 275px;
}

.componentCard,
.componentCardAnchor {
  --color-logo: var(--theme-accent-color);

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: var(--size-24);
  color: var(--theme-accent-color);
  transition: transform var(--duration-250) var(--ease);

  & > * {
    width: 100%;
  }
}

.componentCardAnchor {
  --transition-delay: 0s;
  --transform-after: translateY(100%) scale(0.995);
  --background-image-card: transparent;
  --transition-delay-image-card: 0s;
  --scale-logo: 1;

  &:hover {
    --transition-delay: var(--duration-100);
    --transform-after: translateY(0%) scale(1.025);
    --background-image-card: var(--theme-accent-color-transparent);
    --transition-delay-image-card: var(--duration-400);
    --color-logo: var(--theme-background-color);
    --scale-logo: 0.95;
  }
}

.cardContainer,
.cardAnchor {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 4 / 5;
  padding: var(--size-32) var(--size-40);
  border-radius: var(--radius-15);
  border: 1px solid var(--theme-accent-color-transparent);

  & > .logoLayout {
    width: 100%;
    height: 100%;
  }
}

.cardAnchor {
  z-index: 0;
  position: relative;
  background: var(--background-image-card);
  transition: background var(--duration-400) var(--ease-out-expo);
  transition-delay: var(--transition-delay-image-card);
  overflow: hidden;

  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    inset: 0;
    border-radius: var(--radius-15) var(--radius-15) 0 0;
    background-color: var(--theme-accent-color);
    transform: var(--transform-after);
    transition: transform var(--duration-600) var(--ease-out-expo);
    transition-delay: var(--transition-delay);
  }
}

.componentLogo {
  position: relative;
  display: inline-block;
  mask-size: 100% auto;
  mask-position: center;
  mask-repeat: no-repeat;
  background-color: var(--color-logo);
  transform: scale(var(--scale-logo));
  transition:
    transform var(--duration-250) var(--ease),
    background-color var(--duration-250) var(--ease);
  transition-delay: var(--transition-delay);
}
