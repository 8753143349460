.component {
  position: relative;

  &:has(> .doubleImageLayout + .blocksLayout) > .blocksLayout {
    margin-top: var(--size-8);
  }

  & > .blocksLayout {
    margin-block: var(--size-64);

    &:first-child {
      margin-top: var(--size-32);
    }
  }

  & > .imageLayout {
    margin-block: var(--size-64);
  }

  & > .doubleImageLayout {
    margin-block: var(--size-100);

    &:has(+ .blocksLayout) {
      margin-bottom: 0;
    }
  }

  & > .videoLayout {
    margin-block: var(--size-64);
  }

  & > .quoteLayout {
    margin-block: var(--size-64);
  }

  & > .featuredBenefitsLayout {
    margin-block: var(--size-64);
  }

  & > .cta_small_Layout {
    margin-block: var(--size-72) var(--size-100);
  }

  & > .cta_large_Layout {
    margin-block: var(--size-100) var(--size-150);
  }

  & > .numberInfoSliderLayout {
    margin-block: var(--size-150) var(--size-100);
  }

  & > .relatedJobsLayout {
    margin-top: var(--size-64);
  }

  & > .featuredServicesLayout {
    margin-block: var(--size-48);
  }

  & > .featuredClientsLayout {
    margin-block: var(--size-100);
  }
}
