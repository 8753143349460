.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-20);

  @media (--viewport-md) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: 'search search filter';
  }

  & > .searchLayout {
    @media (--viewport-md) {
      grid-area: search;
    }
  }

  & > .filterLayout {
    @media (--viewport-md) {
      grid-area: filter;
    }
  }
}

.componentSearch {
  display: flex;
  justify-content: space-between;
  color: var(--color-black);
  border-radius: var(--radius-15);
  overflow: hidden;

  &:focus-within {
    outline: 4px auto Highlight;
    outline: 4px auto -webkit-focus-ring-color;
  }

  & > .searchInput {
    width: 100%;
  }
}

.searchInput {
  padding: var(--size-20) 0 var(--size-20) var(--size-32);
  background-color: var(--color-white);
  outline: none;

  &::placeholder {
    opacity: 1;
  }
}

.searchButton {
  padding: 0 var(--size-24);
  background-color: var(--color-white);

  & > .iconLayout {
    width: var(--size-16);
  }
}

.componentFilterButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--size-20) var(--size-24);
  color: var(--color-black);
  background-color: var(--color-white);
  border-radius: var(--radius-15);
  text-align: left;

  & > .iconLayout {
    width: var(--size-16);
  }
}

.componentButtonLabel {
  display: flex;
  align-items: center;
  gap: var(--size-16);
}

.selectedFiltersNum {
  padding: 0 var(--size-12);
  border-radius: var(--radius-5);
  color: var(--color-white);
  background-color: var(--color-black);
}
