.componentBlock {
  @media (--viewport-xl) {
    aspect-ratio: 1;
  }
}

.componentBase {
  --opacity-shadow: 0;
  --transform-shadow: 0, 0;

  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--size-32);
  padding: var(--size-28) var(--size-28) var(--size-32);
  border-radius: var(--radius-10);
  border: 2px solid var(--color-grey-200);
  color: var(--color-black);
  background-color: var(--color-white);
  user-select: none;
  transition: var(--duration-250) var(--ease-out-circ);
  transition-property: transform, border-color;

  &:hover,
  &:focus-visible {
    --transform-shadow: 6px, 6px;
    --opacity-shadow: 1;

    border-color: var(--color-black);
    transform: translate(-3px, -3px) scale(1.0005);
  }

  &:active {
    --transform-shadow: 0, 0;
    --opacity-shadow: 0;

    transform: translate(0, 0);
    transition-duration: var(--duration-100);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: var(--radius-10);
  }

  &::before {
    z-index: -1;
    background-color: var(--color-white);
  }

  &::after {
    opacity: var(--opacity-shadow);
    z-index: -2;
    background-color: var(--color-black);
    transform: translate(var(--transform-shadow));
    transition:
      opacity var(--duration-100) var(--ease),
      transform var(--duration-250) var(--ease-out-circ);
  }

  & > .cardAnchor {
    position: static;
  }
}

.cardAnchor {
  position: relative;
  text-align: left;
  font-family: var(--font-family-base);
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-700);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.metaContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: var(--size-40);

  & > .iconLayout {
    width: var(--size-20);
    min-width: var(--size-20);
  }
}
