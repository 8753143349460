.page {
  z-index: 0;
  position: relative;
  padding-top: var(--size-100);

  & > .heroSimpleContainer,
  & > .heroMetaContainer {
    z-index: 1;
  }

  & > .formLayout {
    margin-top: var(--size-32);
  }

  & > .recruiterLayout {
    margin-block: var(--size-64);
  }
}

.heroSimpleContainer {
  position: relative;

  @media not (--viewport-lg) {
    display: none;
  }
}

.heroMetaContainer {
  position: relative;

  @media (--viewport-lg) {
    display: none;
  }
}

.contentContainer {
  z-index: 0;
  position: relative;

  & > .stickyBarLayout {
    z-index: 1;
    width: 100%;
    top: var(--size-12);
    margin: 0 auto;

    @media (--viewport-sm) {
      width: max-content;
      max-width: 100%;
    }
  }
}

.componentContent {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
  padding: var(--size-150) 0 var(--size-24);

  & > .introductionLayout {
    margin-bottom: var(--size-64);
  }

  & > .portableTextLayout {
    margin-bottom: var(--size-64);
  }

  & > .skillsTagsLayout {
    margin-top: var(--size-12);
    margin-bottom: var(--size-64);
  }

  & > .benefitsLayout {
    margin-bottom: var(--size-64);
  }
}

.componentSkillsTags {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-8);

  & > .tagLayout {
    width: max-content;
    height: max-content;
  }
}
