.page {
  & > .heroLayout {
    min-height: 500px;
    margin-bottom: var(--size-100);
  }

  & > .featuredStoriesLayout {
    margin-top: var(--size-100);
  }
}

.componentBody {
  display: flex;
  flex-direction: column;
  gap: var(--size-80);
}
