.page {
  & > .heroLayout {
    margin-bottom: var(--size-80);
  }
}

.componentBody {
  padding-bottom: var(--size-125);
}

.componentAuthor {
  font-size: var(--font-size-13);

  & > .authorInner {
    width: 200px;
  }
}

.componentContentWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--size-80);
}

.authorInner {
  & > .name {
    margin-top: var(--size-24);
  }
}

.name {
  font-weight: var(--font-weight-700);
}

.role {
  font-weight: var(--font-weight-300);
  color: var(--color-grey-600);
}

.componentFeaturedStories {
  overflow: hidden;
  background-color: var(--theme-background-color);
}

.title {
  color: var(--color-red-500);
}
