.page {
  padding-bottom: var(--size-80);
}

.componentHeroAndFilters {
  padding: var(--size-200) 0 var(--size-28);
  background-color: var(--color-peach-500);
  color: var(--color-red-500);

  @media (--viewport-md) {
    padding: 300px 0 var(--size-40);
  }
}

.componentTitle {
  z-index: 0;
  position: relative;
  display: grid;

  & > * {
    grid-area: 1 / -1;
  }

  & > .jobCountLayout {
    z-index: 1;
    justify-self: end;
    height: var(--size-48);
    width: var(--size-48);

    @media (--viewport-md) {
      height: var(--size-64);
      width: var(--size-64);
    }
  }
}

.componentJobCount {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-family: var(--font-family-italic);
  font-size: var(--font-size-12);
  font-weight: var(--font-weight-700);
  background: var(--color-white);
  transform: translateY(calc(-1 * var(--size-24))) translateX(var(--size-12));

  @media (--viewport-md) {
    font-size: var(--size-20);
    transform: translateY(calc(-1 * var(--size-32))) translateX(var(--size-24));
  }
}

.heroContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);

  & > .titleLayout {
    width: 100%;
    margin: 0 auto;
  }
}
