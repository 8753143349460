[data-style-context='white'] {
  --theme-background-color: var(--color-white);
  --theme-text-color: var(--color-black);
  --theme-header-color: var(--color-black);
  --theme-menu-color: var(--color-white);
  --theme-menu-background-color: var(--color-black);
  --theme-heading-color: var(--color-red-500);
  --theme-button-color: var(--color-black);
  --theme-button-icon-color: var(--color-white);
  --theme-accent-color: var(--color-black);
  --theme-accent-color-transparent: var(--color-black--30);
  --theme-error-color: var(--color-red-500);

  color-scheme: light;
}

[data-style-context='black'] {
  --theme-background-color: var(--color-black);
  --theme-text-color: var(--color-peach-500);
  --theme-header-color: var(--color-white);
  --theme-menu-color: var(--color-black);
  --theme-menu-background-color: var(--color-white);
  --theme-heading-color: var(--color-peach-500);
  --theme-button-color: var(--color-peach-500);
  --theme-button-icon-color: var(--color-black);
  --theme-accent-color: var(--color-white);
  --theme-accent-color-transparent: var(--color-white--30);
  --theme-error-color: var(--color-red-500);

  color-scheme: dark;
}

[data-style-context='peach'] {
  --theme-background-color: var(--color-peach-500);
  --theme-text-color: var(--color-black);
  --theme-header-color: var(--color-black);
  --theme-menu-color: var(--color-white);
  --theme-menu-background-color: var(--color-black);
  --theme-heading-color: var(--color-black);
  --theme-button-color: var(--color-black);
  --theme-button-icon-color: var(--color-peach-500);
  --theme-accent-color: var(--color-black);
  --theme-accent-color-transparent: var(--color-black--30);
  --theme-error-color: var(--color-red-500);

  color-scheme: light;
}

[data-style-context='red'] {
  --theme-background-color: var(--color-red-500);
  --theme-text-color: var(--color-white);
  --theme-header-color: var(--color-white);
  --theme-menu-color: var(--color-white);
  --theme-menu-background-color: var(--color-black);
  --theme-heading-color: var(--color-white);
  --theme-button-color: var(--color-white);
  --theme-button-icon-color: var(--color-white);
  --theme-accent-color: var(--color-black);
  --theme-accent-color-transparent: var(--color-black--30);
  --theme-error-color: var(--color-white);

  color-scheme: light;
}

[data-style-context='dynamic'] {
  --theme-background-color: var(--dynamic-background-color, var(--color-red-500));
  --theme-text-color: var(--dynamic-text-color, var(--color-white));
  --theme-button-icon-color: var(--dynamic-background-color, var(--color-red-500));

  color-scheme: dark;
}
