.component {
  --animation: none;

  display: flex;
  gap: var(--size-16);
  user-select: none;

  &:focus {
    --animation: rotate;
  }

  &:hover {
    @media not (--viewport-sm) {
      --animation: rotate;
    }
  }

  & > .iconContainer {
    width: var(--size-32);
  }
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: var(--animation);
  animation-duration: var(--duration-400);
  animation-timing-function: var(--ease);

  & > .iconLayout {
    width: 100%;
  }
}

@keyframes rotate {
  from { transform: rotate(0turn); }
  to { transform: rotate(-1turn); }
}
