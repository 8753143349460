.component {
  padding: var(--size-64) var(--size-32) var(--size-80);
  border-radius: var(--radius-15);
  color: var(--theme-text-color);
  background-color: var(--theme-background-color);
  overflow: hidden;

  @media (--viewport-md) {
    padding: var(--size-80) var(--size-64) var(--size-125);
  }
}

.component_rootCard {
  width: 275px;
}

.componentCard {
  position: relative;
  aspect-ratio: 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: var(--size-24);
  padding: var(--size-32) var(--size-40);
  color: var(--color-black);
  background-color: var(--color-peach-500);
  border-radius: var(--radius-15);
  overflow: hidden;

  & > .imageLayout {
    width: 85%;
  }
}

.title {
  position: relative;
  text-align: center;
  text-transform: uppercase;
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-30);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-heading-condensed);
}

.description {
  text-align: center;
  font-family: var(--font-family-base);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-subheading);
}
