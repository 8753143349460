.component {
  color: inherit;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  &:active {
    text-decoration: underline;
  }
}
