.component {
  & > .container {
    width: 100%;
  }
}

.container {
  z-index: 0;
  position: relative;
  display: grid;
  align-items: end;

  & > * {
    grid-area: 1 / -1;
  }

  & > .playerContainer {
    z-index: 0;
  }

  & > .imageLayout {
    z-index: 1;
  }

  & > .playButtonLayout {
    z-index: 2;
    position: static;
    margin: var(--size-16);

    @media (--viewport-md) {
      margin: var(--size-32);
    }
  }
}

.playerContainer {
  position: relative;
  aspect-ratio: 16 / 9;

  & > .player {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.componentPlayButton {
  --opacity-hover-label: 0;
  --label-underline-opacity: 0;
  --label-underline-color: var(--color-white);

  position: relative;
  display: inline-flex;
  align-items: center;
  gap: var(--size-16);
  border-radius: var(--radius-15);
  font-family: var(--font-family-base);
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-paragraph);
  color: var(--theme-text-color);

  &.playing {
    pointer-events: none;
    opacity: 0;
  }

  &:hover {
    --opacity-hover-label: 1;
    --label-underline-opacity: 1;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }

  & > .iconLayout {
    height: var(--size-64);
    width: var(--size-64);
  }
}
