.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-40);

  @media (--viewport-lg) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: var(--size-64);
  }

  & > .smallColumnLayout {
    @media (--viewport-lg) {
      grid-column: 1 / 6;
    }
  }

  & > .largeColumnLayout {
    @media (--viewport-lg) {
      grid-column: 6 / 13;
    }
  }
}
