.componentSimpleBase {
  color: var(--color-red-500);
  padding-top: var(--size-225);
}

.componentSimpleLg {
  padding-bottom: var(--size-32);
}

.componentSimpleXxl {
  padding-bottom: var(--size-52);
}

.componentMetaBase {
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--size-40);
  color: var(--theme-heading-color);
  padding-top: var(--size-125);

  & > .stickyContainerLayout {
    z-index: -1;
    top: var(--size-80);
  }
}

.componentMetaMd {
  padding-bottom: var(--size-52);
}

.componentMetaLg {
  padding-bottom: var(--size-24);
}

.componentStickyContainer {
  position: sticky;
}

.componentHeadingAndTags {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: var(--size-40);
}

.componentCtaBase {
  display: grid;
  color: var(--color-red-500);
  text-align: center;
  align-items: end;
  background-color: var(--color-peach-500);
  padding-top: var(--size-225);
  padding-bottom: var(--size-52);

  & > * {
    grid-area: 1 / -1;
  }

  & > .contentWrapperLayout {
    align-self: flex-end;
  }
}

.componentCtaWithImage {
  padding-top: 0;
  padding-bottom: 0;
  color: var(--color-white);
  position: relative;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: var(--color-black--30);
  }

  & > .contentWrapperLayout {
    z-index: 1;
    margin-bottom: var(--size-64);
  }

  & > .imageLayout {
    width: 100%;
    min-height: 100%;
    max-height: 70vh;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);

  & > .subtitle {
    margin-bottom: var(--size-12);
  }

  & > .linkLayout {
    width: max-content;
    align-self: center;
  }
}

.subtitle {
  font-size: var(--size-24);
  font-family: var(--font-family-italic);
}
