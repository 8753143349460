.componentContainer {
  background-color: var(--theme-background-color);
  overflow: hidden;
}

.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-20);
  overflow: visible;
  color: var(--color-red-500);
  padding: var(--size-80) 0;

  & > * {
    width: 100%;
  }

  & > .storyCardsLayout {
    overflow: visible;
  }
}

.componentCardsSlider {
  opacity: 0;
  display: flex;
  align-items: flex-start;
  transition: opacity var(--duration-250) var(--ease);
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  &.isMounted {
    opacity: 1;
  }

  &.sliderDisabled {
    gap: var(--size-20);
    cursor: auto;

    & > * {
      flex: 1;
    }
  }

  & > .slideContainer {
    height: auto;
  }
}

.slideContainer {
  & > .cardLayout {
    width: 100%;
    height: 100%;
  }
}

.componentStoryCard {
  --opacity: 0;

  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: var(--font-size-20);

  @media not (--viewport-md) {
    --opacity: 1;
  }

  &:hover {
    --opacity: 1;
  }

  & > .link {
    position: static;
  }
}

.readMoreLabel {
  opacity: var(--opacity);
  transition: opacity var(--duration-250) var(--ease);
  text-decoration: underline;
  color: var(--color-black);
  font-size: var(--size-20);
}

.link {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.title {
  font-size: var(--font-size-24);
  line-height: var(--line-height-paragraph);
}
