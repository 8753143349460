.component {
  display: flex;
  flex-direction: column-reverse;
  gap: var(--size-32);
  padding-right: var(--size-64);

  @media (--viewport-md) {
    flex-direction: row;
    gap: var(--size-40);
  }

  & > .imageLayout {
    width: var(--size-225);
  }

  & > .blockQuoteLayout {
    flex: 1;
  }
}

.componentBlockQuote {
  color: var(--color-red-500);
  font-family: var(--font-family-italic);
  font-size: var(--font-size-30);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-normal);
  quotes: none;
}

.quoteHighlightElement {
  --progress: 0%;
  --from: var(--color-red-500) var(--progress);
  --to: var(--color-peach-500) calc(var(--progress));

  background-image: linear-gradient(90deg, var(--from), var(--to));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.quoteParagraph {
  &:first-of-type {
    &::before {
      content: '"';
      display: inline-block;
    }
  }

  &:last-of-type {
    &::after {
      content: '"';
      display: inline-block;
    }
  }
}

.source,
.role {
  font-family: var(--font-family-base);
  font-size: var(--font-size-13);
  line-height: var(--line-height-text);
  color: var(--theme-text-color);
  font-style: normal;
}

.source {
  font-weight: var(--font-weight-700);
}

.role {
  font-weight: var(--font-weight-400);
}
