.component {
  padding-block: var(--size-80);
  background-color: var(--color-white);
}

.componentColumn {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);

  @media (--viewport-lg) {
    gap: var(--size-40);
  }
}

.componentJobsList {
  display: flex;
  flex-direction: column;
  gap: var(--size-20);

  @media (--viewport-xl) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewport-xxl) {
    grid-template-columns: repeat(3, 1fr);
  }

  & > .jobCardLayout {
    width: 100%;

    &:nth-child(3) {
      @media not (--viewport-xl) {
        display: none;
      }

      @media (--viewport-xxl) {
        display: none;
      }
    }
  }
}

.componentTitleAndButton {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);

  & > .buttonLayout {
    width: max-content;
  }
}

.componentTitleAndButtonColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--size-8);

  @media (--viewport-md) {
    gap: var(--size-12);
    align-items: flex-start;
  }

  @media (--viewport-lg) {
    flex-direction: row;
    align-items: flex-end;
  }
}
