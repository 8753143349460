.component {
  --padding-top: var(--size-225);
  --heading-height: 0;

  z-index: 0;
  position: relative;
  display: grid;

  & > * {
    grid-area: 1 / -1;
  }

  & > .filterLayout {
    z-index: 1;
    width: 100%;
    height: calc(var(--padding-top) + var(--heading-height));
  }
}

.componentFilter {
  & > .filterWrapper {
    width: 100%;
    height: var(--size-175);
    top: 0;
  }
}

.filterWrapper {
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  & > .filterButtonLayout {
    min-width: var(--size-225);
  }
}

.componentFilterButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--size-24);
  padding: var(--size-12) var(--size-24);
  color: var(--color-white);
  border: 1px solid var(--color-white);
  border-radius: var(--radius-15);
  font-family: var(--font-family-base);
  font-size: var(--font-size-16-18);
  font-weight: var(--font-weight-300);

  & > .iconLayout {
    width: var(--size-16);
  }
}

.contentContainer {
  z-index: 0;
  position: relative;
  padding-top: var(--padding-top);

  & > .headingLayout {
    z-index: -1;
    top: var(--padding-top);
    margin-bottom: var(--size-32);
  }
}

.componentHeading {
  position: sticky;
  color: var(--color-red-500);
  user-select: none;
  pointer-events: none;
}

.componentHeadingLine {
  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.componentGridBase {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(20, var(--size-80));

  @media (--viewport-md) {
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: repeat(10, var(--size-80));
  }

  & > .cardLayout {
    height: max-content;

    &:nth-of-type(1) {
      grid-area: __1;
    }

    &:nth-of-type(2) {
      grid-area: __2;
    }

    &:nth-of-type(3) {
      grid-area: __3;
    }

    &:nth-of-type(4) {
      grid-area: __4;
    }
  }
}

.componentGridA {
  grid-template-areas:
    /*           1   2   3   4   5   6  */
    /* 1    */  '__1 __1 __1 __1 ... ...'
    /* 2    */  '... ... ... ... ... ...'
    /* 3    */  '... ... ... ... ... ...'
    /* 4    */  '... ... ... ... ... ...'
    /* 5    */  '... ... ... ... ... ...'
    /* 6    */  '... ... ... ... ... ...'
    /* 7    */  '... ... ... ... ... ...'
    /* 8    */  '... ... ... ... ... ...'
    /* 9    */  '... ... ... ... ... ...'
    /* 10   */  '... ... ... ... __2 __2'
    /* 11   */  '__3 __3 __3 ... ... ...'
    /* 12   */  '... ... ... ... ... ...'
    /* 13   */  '... ... ... ... ... ...'
    /* 14   */  '... ... ... ... ... ...'
    /* 15   */  '... ... ... ... ... ...'
    /* 16   */  '... ... ... ... ... ...'
    /* 17   */  '... ... ... ... ... ...'
    /* 18   */  '... __4 __4 ... ... ...'
    /* 19   */  '... ... ... ... ... ...'
    /* 20   */  '... ... ... ... ... ...';

  @media (--viewport-md) {
    grid-template-areas:
      /*           1   2   3   4   5   6   7   8   9   10  11  12  13  14  15  16  17  18  19  20  21  22  23  24 */
      /* 1    */  '... ... ... ... ... ... ... ... __1 __1 __1 __1 __1 __1 __1 __1 ... ... ... ... ... ... ... ...'
      /* 2    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... __3 __3 __3'
      /* 3    */  '__2 __2 __2 __2 __2 ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 4    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 5    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 6    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 7    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 8    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... __4 __4 __4 ... ...'
      /* 9    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 10   */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...';
  }

  @media (--viewport-xl) {
    padding-bottom: var(--size-100);
  }

  @media (--viewport-xxl) {
    padding-bottom: var(--size-225);
  }
}

.componentGridB {
  grid-template-areas:
    /*           1   2   3   4   5   6  */
    /* 1    */  '... ... ... ... __1 __1'
    /* 2    */  '... ... ... ... ... ...'
    /* 3    */  '... ... ... ... ... ...'
    /* 4    */  '__2 __2 __2 ... ... ...'
    /* 5    */  '... ... ... ... ... ...'
    /* 6    */  '... ... ... ... ... ...'
    /* 7    */  '... ... ... ... ... ...'
    /* 8    */  '... ... ... ... ... ...'
    /* 9    */  '... ... ... ... ... ...'
    /* 10   */  '... ... ... ... ... ...'
    /* 11   */  '... ... ... __3 __3 __3'
    /* 12   */  '... ... ... ... ... ...'
    /* 13   */  '... ... ... ... ... ...'
    /* 14   */  '... ... ... ... ... ...'
    /* 15   */  '__4 __4 ... ... ... ...'
    /* 16   */  '... ... ... ... ... ...'
    /* 17   */  '... ... ... ... ... ...'
    /* 18   */  '... ... ... ... ... ...'
    /* 19   */  '... ... ... ... ... ...'
    /* 20   */  '... ... ... ... ... ...'
    /* 21   */  '... ... ... ... ... ...'
    /* 22   */  '... ... ... ... ... ...'
    /* 23   */  '... ... ... ... ... ...'
    /* 24   */  '... ... ... ... ... ...';

  @media (--viewport-md) {
    grid-template-areas:
      /*           1   2   3   4   5   6   7   8   9   10  11  12  13  14  15  16  17  18  19  20  21  22  23  24 */
      /* 1    */  '__1 __1 __1 __1 __1 ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 2    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 3    */  '... ... ... ... ... ... ... __2 __2 __2 __2 ... ... __3 __3 __3 __3 ... ... ... ... ... ... ...'
      /* 4    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 5    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 6    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... __4 __4 __4 __4 __4'
      /* 7    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 8    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 9    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 10   */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...';
  }

  @media (--viewport-xl) {
    padding-bottom: var(--size-150);
  }
}

.componentGridC {
  grid-template-areas:
    /*           1   2   3   4   5   6  */
    /* 1    */  '... __1 __1 __1 __1 ...'
    /* 2    */  '... ... ... ... ... ...'
    /* 3    */  '... ... ... ... ... ...'
    /* 4    */  '... ... ... ... ... ...'
    /* 5    */  '... ... ... ... ... ...'
    /* 6    */  '... ... ... ... ... ...'
    /* 7    */  '... ... ... ... ... ...'
    /* 8    */  '... ... ... ... ... ...'
    /* 9    */  '... ... ... ... ... ...'
    /* 10   */  '... ... ... ... ... ...'
    /* 11   */  '__2 __2 __2 ... ... ...'
    /* 12   */  '... ... ... ... ... ...'
    /* 13   */  '... ... ... ... ... ...'
    /* 14   */  '... ... ... ... __3 __3'
    /* 15   */  '... ... ... ... ... ...'
    /* 16   */  '... ... ... ... ... ...'
    /* 17   */  '... ... ... ... ... ...'
    /* 18   */  '__4 __4 ... ... ... ...'
    /* 19   */  '... ... ... ... ... ...'
    /* 20   */  '... ... ... ... ... ...'
    /* 21   */  '... ... ... ... ... ...'
    /* 22   */  '... ... ... ... ... ...'
    /* 23   */  '... ... ... ... ... ...'
    /* 24   */  '... ... ... ... ... ...';

  @media (--viewport-md) {
    grid-template-areas:
      /*           1   2   3   4   5   6   7   8   9   10  11  12  13  14  15  16  17  18  19  20  21  22  23  24 */
      /* 1    */  '... __2 __2 __2 ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 2    */  '... ... ... ... ... ... ... ... __3 __3 __3 ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 3    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 4    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... __1 __1 __1 __1 __1 __1 __1 __1 ...'
      /* 5    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 6    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 7    */  '... ... __4 __4 __4 __4 __4 ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 8    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 9    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 10   */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...';
  }

  @media (--viewport-lg) {
    padding-bottom: var(--size-150);
  }

  @media (--viewport-xl) {
    padding-bottom: 300px;
  }
}

.componentGridD {
  grid-template-areas:
    /*           1   2   3   4   5   6  */
    /* 1    */  '... ... ... ... ... ...'
    /* 2    */  '... ... ... ... ... ...'
    /* 3    */  '... ... ... __1 __1 __1'
    /* 4    */  '... ... ... ... ... ...'
    /* 5    */  '... ... ... ... ... ...'
    /* 6    */  '... ... ... ... ... ...'
    /* 7    */  '... ... ... ... ... ...'
    /* 8    */  '__2 __2 ... ... ... ...'
    /* 9    */  '... ... ... ... ... ...'
    /* 10   */  '... ... ... ... ... ...'
    /* 11   */  '... ... ... __3 __3 __3'
    /* 12   */  '... ... ... ... ... ...'
    /* 13   */  '... ... ... ... ... ...'
    /* 14   */  '... ... ... ... ... ...'
    /* 15   */  '__4 __4 ... ... ... ...'
    /* 16   */  '... ... ... ... ... ...'
    /* 17   */  '... ... ... ... ... ...'
    /* 18   */  '... ... ... ... ... ...'
    /* 19   */  '... ... ... ... ... ...'
    /* 20   */  '... ... ... ... ... ...';

  @media (--viewport-md) {
    grid-template-areas:
      /*           1   2   3   4   5   6   7   8   9   10  11  12  13  14  15  16  17  18  19  20  21  22  23  24 */
      /* 1    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 2    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 3    */  '... ... ... ... ... ... ... ... ... ... ... ... __3 __3 __3 __3 ... ... ... ... ... ... ... ...'
      /* 4    */  '... ... ... ... ... ... __2 __2 __2 __2 __2 ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 5    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... __4 __4 __4 __4 __4 ...'
      /* 6    */  '__1 __1 __1 __1 ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 7    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 8    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 9    */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
      /* 10   */  '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...';
  }

  @media (--viewport-lg) {
    padding-bottom: var(--size-100);
  }

  @media (--viewport-xl) {
    padding-bottom: var(--size-150);
  }

  @media (--viewport-xl) {
    padding-bottom: var(--size-225);
  }
}

.componentCard {
  --opacity-hover-label: 0;

  pointer-events: none;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
  user-select: none;

  &:hover {
    --opacity-hover-label: 1;
  }

  & > .anchorLayout {
    position: static;
  }

  &.isInViewport {
    pointer-events: auto;
  }
}

.componentCardAnchor {
  position: relative;
  display: flex;
  flex-direction: column;

  & > .titleLayout {
    position: static;
  }
}

.componentCardTitleDefault,
.cardTitleMain {
  position: relative;

  & > .anchor {
    position: static;
  }
}

.componentCardTitleMain {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--size-12);

  & > .cardTitleMain {
    position: static;
  }
}

.componentCardTitleDefault,
.introduction {
  display: -webkit-box;
  color: var(--color-white);
  font-family: var(--font-family-base);
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-paragraph);
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.componentCardTitleDefault {
  -webkit-line-clamp: 3;
}

.introduction {
  -webkit-line-clamp: 2;
}

.cardTitleMain {
  display: -webkit-box;
  color: var(--color-white);
  font-family: var(--font-family-base);
  font-size: var(--font-size-30-40);
  font-weight: var(--font-weight-700);
  line-height: var(--line-height-paragraph);
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.anchor {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.cardHoverLabel {
  opacity: var(--opacity-hover-label);
  color: var(--color-red-500);
  font-size: var(--font-size-16-18);
  text-decoration: underline;
  transition: opacity var(--duration-250) var(--ease);
  pointer-events: none;
}
