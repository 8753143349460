.component {
  display: flex;
  gap: var(--size-12);

  @media (--viewport-md) {
    gap: var(--size-16);
  }
}

.componentSliderButton {
  padding: var(--size-16);
  color: var(--theme-text-color);
  border: 2px solid var(--theme-text-color);
  border-radius: var(--radius-15);
  transition: opacity var(--duration-250) var(--ease);

  &:first-of-type {
    transform: rotate(180deg);
  }

  &:disabled {
    opacity: 0.3;
  }

  & > .iconContainer {
    width: var(--size-24);
    height: var(--size-24);
  }
}

.iconContainer {
  display: block;

  & > .iconLayout {
    width: 100%;
    height: 100%;
  }
}
