.componentHero {
  padding: var(--size-200) 0 var(--size-28);
  color: var(--theme-heading-color);
  background-color: var(--theme-background-color);
}

.componentContent {
  position: relative;
  overflow: hidden;

  & > .blocksLayout {
    margin-block: var(--size-64);

    &:first-child {
      margin-top: var(--size-32);
    }
  }

  & > .cookieSettingsLayout {
    margin-block: var(--size-125);
  }
}
