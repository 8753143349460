.component {
  padding: var(--size-52) 0 var(--size-80);
  color: var(--color-red-500);
  background-color: var(--theme-background-color);

  @media (--viewport-lg) {
    padding: var(--size-80) 0 var(--size-150);
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: var(--size-64);

  @media (--viewport-lg) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: none;
  }

  & > .storyImagesLayout {
    @media (--viewport-lg) {
      grid-column: 1 / 7;
    }
  }

  & > .ctaBlockLayout {
    @media (--viewport-lg) {
      grid-column: 7 / 13;
    }
  }
}

.componentHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.componentHeadingLine {
  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.componentStoryImages {
  display: grid;
  place-items: center;
  padding: var(--size-12);

  & > .cardLayout {
    grid-area: -1 / 1;
    width: 250px;

    @media (--viewport-md) {
      width: 370px;
    }

    @media (--viewport-lg) {
      width: 250px;
    }

    @media (--viewport-xl) {
      width: 370px;
    }
  }
}

.componentStoryCard {
  transition: transform var(--duration-250) var(--ease);
  transition-delay: var(--duration-100);

  &.wasInViewport {
    &:nth-of-type(1) {
      transform-origin: right top;
      transform: scale(0.4) translate(40%, -40%);
    }

    &:nth-of-type(2) {
      transform-origin: left center;
      transform: scale(0.75) translate(-40%, -30%);
    }

    &:nth-of-type(3) {
      transform: scale(0.9) translate(0, 0);
    }

    &:nth-of-type(4) {
      transform-origin: left bottom;
      transform: scale(0.55) translate(-30%, 20%);
    }
  }

  & > .imageLayout {
    width: 100%;
  }
}

.componentCtaBlock {
  display: flex;
  flex-direction: column;
  gap: var(--size-40);

  @media (--viewport-lg) {
    padding: var(--size-80) var(--size-32) var(--size-125) var(--size-100);
  }

  & > .description {
    max-width: 400px;

    @media (--viewport-lg) {
      max-width: 300px;
    }
  }

  & > .buttonLayout {
    width: max-content;
  }
}

.description {
  color: var(--theme-text-color);
}
