.component {
  background-color: var(--theme-background-color);
}

.formSection {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
  padding: var(--size-80) 0;
  color: var(--theme-heading-color);

  @media (--viewport-lg) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'title form';
    gap: var(--size-80);
  }

  & > .formSectionTitleContainer {
    max-width: 450px;

    @media (--viewport-lg) {
      grid-area: title;
    }
  }

  & > .formLayout {
    @media (--viewport-lg) {
      grid-area: form;
    }
  }
}

.formSectionTitleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-24);
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  scroll-margin-top: var(--size-100);
  color: var(--theme-text-color);
}
