.componentContent,
.contentBlock {
  background-color: var(--theme-background-color);
  overflow: hidden;

  & > .featuredExpertises {
    margin-block: var(--size-100);
  }

  & > .featuredSectors {
    margin-block: var(--size-100);
  }

  & > .featuredLabelsLayout {
    margin-block: var(--size-125) var(--size-175);
  }
}
