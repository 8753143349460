.componentBlack {
  color: var(--color-white);
  background-color: var(--color-black);
}

.componentPeach {
  color: var(--color-black);
  background-color: var(--color-peach-200);
}

.componentWhiteTransparent {
  color: var(--color-black);
  background-color: var(--color-white--30);
}

.componentBase {
  padding: var(--size-8) var(--size-16);
  border-radius: var(--radius-20);
  font-family: var(--font-family-base);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
}

.componentDropdown {
  z-index: 0;
  position: relative;
  display: grid;
  font-family: var(--font-family-base);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
  color: var(--color-black);

  & > * {
    grid-area: 1 / -1;
  }

  & > .dropdownButton {
    z-index: 1;
    position: static;
    width: 100%;
  }

  & > .dropdownListLayout {
    position: absolute;
    min-width: 100%;
    top: 50%;
  }
}

.dropdownButton {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--size-12);
  padding: 0 var(--size-4) 0 var(--size-12);
  border-radius: var(--radius-20);
  background-color: var(--color-peach-200);
  text-align: left;
  pointer-events: auto;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }

  & > .iconContainer {
    height: var(--size-32);
  }
}

.labelsContainer {
  display: grid;
  /* pointer-events: none; */
  opacity: 0;

  & > * {
    grid-area: 1 / -1;
  }
}

.labelWrapper {
  position: relative;

  &::before {
    content: attr(data-content);
    width: 100%;
    left: 0;
    top: 0;
    padding: 0 var(--size-24) 0 var(--size-8);
    white-space: nowrap;
  }
}

.dropdownButtonLabel {
  padding-block: var(--size-8);
}

.iconContainer {
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--color-grey-900);
  background-color: var(--color-white);
  transition: transform var(--duration-250) var(--ease);

  &.isOpen {
    transform: rotate(-0.5turn);
  }

  & > .iconLayout {
    width: var(--size-12);
  }
}

.componentDropdownList {
  pointer-events: none;
  display: grid;
  grid-template-rows: 0fr;
  border-radius: 0 0 var(--radius-20) var(--radius-20);
  background-color: var(--color-peach-200);
  transition: var(--duration-400) var(--ease-out-expo);
  transition-property: grid-template-rows padding;
  overflow: hidden;

  &.isOpen {
    pointer-events: auto;
    grid-template-rows: 1fr;
    padding-top: var(--size-20);
    padding-bottom: var(--size-12);
  }

  & > * {
    min-height: 0;
  }
}

.dropdownList {
  display: flex;
  flex-direction: column;
}

.componentDropdownLabel {
  padding: 2px var(--size-24) 2px var(--size-8);

  &:nth-child(odd) {
    background-color: var(--color-white--30);
  }
}
