.section {
  z-index: 0;
  position: relative;
  display: grid;
  padding-top: var(--size-80);
  color: var(--theme-text-color);
  background-color: var(--theme-background-color);

  @media (--viewport-md) {
    padding-top: var(--size-125);
  }

  & > * {
    grid-area: 1 / -1;
  }

  & > .sectionContentWrapper {
    z-index: 1;
  }

  & > .illustrationLayout {
    align-self: end;
  }
}

.sectionContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--size-40);
  padding-bottom: var(--size-80);

  @media (--viewport-md) {
    flex-direction: row;
    align-items: center;
    gap: var(--size-32);
    padding-bottom: var(--size-125);
  }

  & > .titleAndIntroductionLayout {
    @media (--viewport-sm) {
      max-width: 350px;
    }
  }
}

.illustration {
  display: none;
  padding-left: 30%;

  @media (--viewport-md) {
    display: block;
  }
}

.componentCard {
  z-index: 0;
  position: relative;

  &:hover {
    &::before {
      transform: scale(0.95) rotate(0deg);
    }

    &::after {
      transform: scale(0.95) rotate(0deg);
    }
  }

  &::before,
  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: var(--radius-15);
    border: 2px solid var(--color-black);
    background-color: var(--color-white);
    transition: transform var(--duration-250) var(--ease-out-circ);
    pointer-events: none;
  }

  &::before {
    display: block;
    transform: rotate(-6deg);
    transition-delay: 50ms;

    @media (--viewport-md) {
      transform: translateY(var(--size-24)) rotate(-6deg);
    }
  }

  &::after {
    display: none;
    transform: rotate(4deg);

    @media (--viewport-md) {
      display: block;
    }
  }

  & > .cardContainer {
    position: static;
  }
}

.cardContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-48);
  padding: var(--size-28) var(--size-24);
  border-radius: var(--radius-15);
  color: var(--color-white);
  background-color: var(--color-red-500);

  @media (--viewport-md) {
    padding: var(--size-32) var(--size-52) var(--size-40);
  }

  @media (--viewport-lg) {
    padding: var(--size-52) var(--size-64) var(--size-64);
    border-radius: var(--radius-20);
  }

  & > .buttonLayout {
    position: static;
    width: 100%;

    @media (--viewport-md) {
      width: max-content;
    }
  }
}

.cardTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-16);

  & > .subtitleLayout {
    width: 100%;
  }

  & > .logo {
    max-width: var(--size-175);

    @media (--viewport-lg) {
      max-width: var(--size-250);
    }
  }
}

.componentSubtitle {
  display: inline-block;
  font-family: var(--font-family-italic);
  font-size: var(--font-size-18);
  text-align: center;
}

.listItem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--size-32);
  padding: var(--size-28) var(--size-28) var(--size-32);
  border-radius: var(--radius-10);
  color: var(--color-white);
  background-color: var(--color-red-500);

  @media (--viewport-md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  & > .buttonLayout {
    position: static;
  }
}

.componentTitleAndIntroductionBase {
  display: flex;
  flex-direction: column;
  gap: var(--size-8);
}

.componentTitleAndIntroductionMd {
  --font-size-title: var(--font-size-22);
  --font-size-introduction: var(--font-size-18);

  gap: var(--size-8);
}

.componentTitleAndIntroductionLg {
  --font-size-title: var(--font-size-40-48);
  --font-size-introduction: var(--font-size-18-20);

  gap: var(--size-24);
}

.title {
  font-family: var(--font-family-base);
  font-size: var(--font-size-title);
  font-weight: var(--font-weight-700);
  line-height: var(--line-height-subheading);
}

.introduction {
  font-family: var(--font-family-base);
  font-size: var(--font-size-introduction);
  font-weight: var(--font-weight-400);
}

.componentButton {
  --transition-duration: var(--duration-250);

  position: relative;
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-400);
  text-transform: uppercase;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }

  &:hover,
  &:focus-visible {
    --transform-shadow: 6px, 6px;
    --opacity-shadow: 1;
    --transform: translate(-3px, -3px) scale(1.0005);
  }

  &:active {
    --transform-shadow: 0, 0;
    --opacity-shadow: 0;
    --transform: translate(0, 0);
    --transition-duration: var(--duration-100);
  }
}

.buttonBackground {
  z-index: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--size-16) var(--size-48);
  color: var(--color-black);
  background-color: var(--color-white);
  border-radius: var(--radius-10);
  border: 2px solid var(--color-black);
  transform: var(--transform);
  transition: transform var(--transition-duration) var(--ease-out-circ);

  &::before,
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: var(--radius-10);
  }

  &::before {
    z-index: -1;
    background-color: var(--color-white);
  }

  &::after {
    opacity: var(--opacity-shadow);
    z-index: -2;
    background-color: var(--color-black);
    transform: translate(var(--transform-shadow));
    transition:
      opacity var(--duration-100) var(--ease),
      transform var(--duration-250) var(--ease-out-circ);
  }
}
