.component {
  padding-top: var(--size-80);

  @media (--viewport-md) {
    padding-top: var(--size-100);
  }

  & > .introductionLayout {
    &:last-child {
      margin-bottom: var(--size-64);

      @media (--viewport-md) {
        margin-bottom: var(--size-100);
      }
    }
  }

  & > .illustrationLayout {
    &:not(:first-child) {
      margin-top: var(--size-64);

      @media (--viewport-md) {
        margin-top: var(--size-100);
      }
    }
  }
}
