.componentBase {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > * {
    width: 100%;
  }
}

.componentRow {
  display: flex;
  gap: var(--size-12);

  & > * {
    flex: 1;
  }
}

.componentText,
.componentNumber,
.componentEmail {
  & > .inputLayout {
    height: var(--size-48);
  }
}

.componentDescription {
  font-style: italic;
}

.componentTextarea {
  display: flex;
}

.componentLabel {
  position: relative;
  font-size: var(--font-size-13);
  opacity: 0.7;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.componentSelect {
  display: flex;
}

.componentCheckbox {
  display: flex;
  justify-content: flex-start;
}
