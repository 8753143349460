.componentSmall {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: var(--size-32);
  padding: var(--size-24);
  color: var(--theme-text-color);
  background-color: var(--theme-background-color);

  @media (--viewport-lg) {
    grid-template-columns: 1fr 1fr;
    gap: var(--size-52);
    padding: var(--size-52);
  }

  & > .buttonLayout {
    justify-self: flex-end;
  }
}

.componentLarge {
  padding: var(--size-32);
  color: var(--theme-text-color);
  background-color: var(--theme-background-color);
}

.componentContentLarge {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-24);

  & > .textContainer {
    @media (--viewport-xl) {
      margin-right: var(--size-200);
    }
  }

  & > .buttonLayout {
    margin-top: auto;
    place-self: flex-end;
    align-self: end;
    justify-self: flex-end;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-24);
  padding-top: var(--size-16);
}

.componentFull {
  padding-block: var(--size-52);
  color: var(--theme-text-color);
  background-color: var(--theme-background-color);

  @media (--viewport-lg) {
    padding-block: var(--size-72);
  }
}

.componentContentFull {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-32);
}

.description {
  font-family: var(--font-family-base);
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-text);
}
