.component {
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--theme-button-icon-color);
  background-color: var(--theme-accent-color);
  border-radius: 50%;
  transition: transform var(--duration-250) var(--ease);

  &:hover {
    transform: scale(1.2);
  }

  &:active {
    transform: scale(0.95);
  }

  & > .iconLayout {
    width: var(--size-16);
  }
}
