.component {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--size-32);

  & > .arrowLayout {
    width: var(--size-20);
    height: var(--size-20);
  }
}

.bullets {
  display: flex;
  align-items: center;
  gap: var(--size-18);
}

.hellip {
  display: flex;
  align-items: center;
  justify-content: center;
}

.componentBullet {
  font-size: var(--font-size-16-18);
  font-weight: var(--font-weight-300);
  border-bottom: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active,
  &:focus {
    font-weight: var(--font-weight-700);
    border-bottom: 1px solid var(--color-black);
  }

  &:hover {
    font-weight: var(--font-weight-700);
  }
}

.item {
  padding: var(--size-8);

  & > .bulletLayout {
    width: var(--size-20);
    height: var(--size-20);
  }
}

.componentButton {
  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  }
}
