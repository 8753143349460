.component {
  font-family: var(--font-family-condensed);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-heading-condensed);
  text-transform: uppercase;
  pointer-events: none;
  user-select: none;
}

.component,
.text {
  --captured-length: initial;
  --support-sentinel: var(--captured-length, 9999px);

  display: flex;
  container-type: inline-size;

  & > .visibleTitle {
    flex-grow: 1;
  }
}

.hiddenTitle {
  visibility: hidden;
}

.visibleTitle {
  container-type: inline-size;

  --captured-length: 100cqi;
  --available-space: var(--captured-length);

  & > * {
    --font-size: clamp(1em, 1em * var(--ratio), var(--max-font-size, infinity * 1px) - var(--support-sentinel));
    --support-sentinel: inherit;
    --captured-length: 100cqi;
    --ratio: tan(
      atan2(
        var(--available-space) - var(--icon-size, 0px),
        var(--available-space) - var(--captured-length) - var(--icon-size, 0px)
      )
    );

    inline-size: calc(var(--available-space) + 1px);

    @container (width > 0) {
      white-space: nowrap;
    }

    &.text {
      --captured-length2: var(--font-size);

      font-variation-settings: 'opsz' tan(atan2(var(--captured-length2), 1px));
    }

    &:not(.text) {
      display: block;
      font-size: var(--font-size);
    }
  }
}

@property --captured-length {
  syntax: "<length>";
  initial-value: 0px;
  inherits: true;
}

@property --captured-length2 {
  syntax: "<length>";
  initial-value: 0px;
  inherits: true;
}
